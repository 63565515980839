@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a {
    color: inherit;
    text-decoration: none;
}
:root {
    --antd-wave-shadow-color: #3943a8;
}
/* .ant-btn {
    background: ${colors.primary};
    border-color: ${colors.primary};
  } */
body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;

    font-family: "Inter", sans-serif;
}
